import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

import { Link } from "gatsby";

const breadCrumbLevels = {
    Koti: "/",
    // Blogi: "/blog",
    "Oikeudellinen ilmoitus": "fi/oikeudellinen-ilmoitus"
  };
  
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/legal-notice"
);

const LegalNotice = props => {
    return (
      <Layout location={props.location} alternateLangs={alternateLangs}>
  
        <React.Fragment>
          <SEO
            title="Oikeudelliset Ilmoitukset"
            description="Oikeudelliset ilmoitukset Matthias Kupperschmidtin palveluista verkkomarkkinoinnin alalla; tähän kuuluu hakukoneoptimointi, web-analytiikan toteutukset, konsultointi verkkomarkkinoinnissa"
            lang="fi"
            alternateLangs={alternateLangs}
          />
          <MainContent
            article={false}
  
          >
            <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
  
            <H as="h1">Oikeudellinen Ilmoitus</H>

<p>Matthias Kupperschmidt tarjoaa erilaisia palveluita (jäljempänä "palvelut") verkkomarkkinoinnin alalla; tähän kuuluu hakukoneoptimointi, web-analytiikan toteutukset, konsultointi verkkomarkkinoinnin aiheissa sekä koulutuskurssit ja työpajat.</p>

<p>Tarjottujen palveluiden käyttö on sallittua vain näissä yleisissä ehdoissa ja/tai tilauksessa kuvatun tarkoitetun käyttötarkoituksen mukaisesti. Lisäksi kaikenlainen muu käyttö tai käsittely on sallittua vain Tanskan tietosuojalakien, erityisesti tietosuojalain ja EU:n yleisen tietosuoja-asetuksen (GDPR) tiukkojen ehtojen mukaisesti. Kaikenlainen väärinkäyttö tai hyväksikäyttö ei ole sallittua. Erityisesti palveluiden ja sisällön täydellinen käyttö kaupallisiin tarkoituksiin missä tahansa muodossa (painettuna, elektronisesti, CD:llä jne.) sekä hakemistojen, tietokantojen jne. lukeminen edellä mainittuihin tarkoituksiin tai muuhun kaupalliseen käyttöön ei ole sallittua ja palveluntarjoaja syyttää tästä lain mukaan kaikilla käytettävissä olevilla oikeudellisilla keinoilla.</p>
<p>Yksittäisten palveluiden saatavuutta sekä sisällön/merkintöjen/tietojen oikeellisuutta ja täydellisyyttä ei taata.</p>
<p>Nämä ehdot säätelevät Matthias Kupperschmidtin ja hänen asiakkaidensa ja sopimuskumppaneidensa (jäljempänä "sopimuskumppani") välisiä suhteita.</p>

<p>Osa 1 Soveltamisala</p>
<p>Matthias Kupperschmidtin palvelut suoritetaan yksinomaan näiden yleisten ehtojen perusteella. Siksi ne koskevat myös kaikkia tulevia liiketoimintasuhteita, vaikka niitä ei nimenomaisesti sovittaisi uudelleen.</p>
<p>Matthias Kupperschmidtin tarjoamien palveluiden käyttö sopimuskumppanille on yksinomaan näiden yleisten ehtojen (CTG) sekä kyseisten tuotteiden/palveluiden erityisehtojen alainen. Tarjousten, palveluiden, yksittäisten projektien, kampanjoiden ja muiden palveluiden osalta, erityisesti projektipalvelut niiden ulkopuolella, sovelletaan erikseen sovittavia ehtoja.</p>
<p>Sopimuskumppaneiden tai muiden kolmansien osapuolten yleisiä ehtoja ei sovelleta, vaikka Matthias Kupperschmidt ei nimenomaisesti vastusta niitä ja/tai suorittaa palvelunsa ilman vastustusta.</p>

<p>Osa 2 Palveluiden yleinen laajuus</p>
<p>Matthias Kupperschmidt on aktiivinen hakukoneoptimoinnin, web-analytiikan, inbound-markkinoinnin, verkkomarkkinoinnin konsultoinnin sekä koulutuskurssien ja työpajojen aloilla. Kunkin tilatun palvelun yksityiskohtainen kuvaus näkyy vastaavasta tilauksesta.</p>
<p>Matthias Kupperschmidtin tarjoamat palvelut ja sisällöt tarjotaan ja julkaistaan Matthias Kupperschmidtin toimesta, ellei toisin ole erikseen ilmoitettu. Kaikki kolmansien osapuolien saataville asettama sisältö on kyseisen luojan, tekijän tai jakelijan tarjoamaa tietoa, ei Matthias Kupperschmidtin tarjoamaa tietoa. Siksi Matthias Kupperschmidt ei ole vastuussa tämän sisällön tarkkuudesta tai luotettavuudesta.</p>
<p>Matthias Kupperschmidtin tarjoamat palvelut ovat vain kutsu tehdä ehdotus sopimuksen tekemiseksi. Sopimuskumppani on sidottu ilmoitukseensa kymmenen työpäivän ajan. Sopimuksen tekeminen edellyttää Matthias Kupperschmidtin vahvistusta tekstimuodossa (sähköposti riittää). Sopimus syntyy sitten sovellettavien yleisten ehtojen perusteella.</p>
<p>Matthias Kupperschmidtin ei tarvitse tehdä sopimuksia. Hänellä on oikeus kieltäytyä tekemästä sopimuksia ilman perusteluja. Sopimusten myöhemmät muutokset ja lisäykset edellyttävät Matthias Kupperschmidtin kirjallista vahvistusta. Sopimuskumppanit tekevät erilliset sopimukset lisäpalveluista.</p>
<p>Matthias Kupperschmidt pidättää myös oikeuden sulkea sopimuskumppaneita myöhemmin palveluiden käytöstä, jos on todisteita siitä, että sopimuskumppani väärinkäyttää palveluita tai käyttää niitä tavalla, joka saattaa vaarantaa Matthias Kupperschmidtin maineen. Tämä koskee erityisesti tapauksia, joissa sopimuskumppani rikkoo sopimussuhteesta johtuvia velvollisuuksia tai vahingoittaa Matthias Kupperschmidtin muita sopimuskumppaneita.</p>
<p>Siltä osin kuin sopimuskumppani käyttää toisen sopimuskumppanin ohjelmistoa osana palveluiden käyttöä, toinen sopimuskumppani myöntää hänelle – ellei toisin ole erikseen sovittu – ei-yksinomaisen, ei-siirrettävän käyttöoikeuden, joka on rajoitettu ajallisesti sopimuksen kestoon ja sisällöllisesti palvelun tarjoamisen merkityksessä. Muita käyttöoikeuksia ei myönnetä sopimuskumppanille.</p>
<p>Huoltotarkoituksia ja korjausten suorittamista varten Matthias Kupperschmidt on oikeutettu keskeyttämään palvelujen tai niiden osien tarjoamisen sopimuskumppanille kohtuullisessa määrin. Yleensä Matthias Kupperschmidt pyrkii suorittamaan huolto- ja korjaustyöt aikoina, jolloin niiden rasittava vaikutus on mahdollisimman vähäinen. Tämä ei anna sopimuskumppanille oikeutta vaatia hyvitystä tai korvausta, ellei Matthias Kupperschmidt ole syyllinen.</p>
<p>Matthias Kupperschmidt on oikeutettu solmimaan vastaavia sopimussuhteita sopimuskumppanin kilpailijoiden kanssa. Matthias Kupperschmidt pyrkii – ilman että hän olisi siihen velvollinen – suojelemaan sopimuskumppanin oikeutettuja etuja tässä suhteessa ja välttämään eturistiriitoja. Matthias Kupperschmidt on oikeutettu tarjoamaan osapalveluita sekä kolmansien osapuolten palveluita.</p>
<p>Sopimuskumppanin pyytämät määräajat ja päivämäärät ovat sitovia vain, kun Matthias Kupperschmidt on nimenomaisesti vahvistanut ne kirjallisesti ja jos sopimuskumppani on luonut ajoissa kaikki palvelun tarjoamiseen tarvittavat edellytykset, jotka ovat hänen vaikutuspiirissään.</p>
<p>Siinä määrin ja niin kauan kuin sopimuskumppani ei ole täyttänyt mitään palvelun tarjoamiseen tarvittavista yhteistyövelvoitteistaan, jopa Matthias Kupperschmidtin kirjallisesta pyynnöstä ja kohtuullisen määräajan asettamisesta huolimatta, Matthias Kupperschmidt vapautuu tämän laiminlyönnin vaikutuksesta kyseisen velvoitteen täyttämisestä projektisopimuksessa sovitussa määräajassa. Tilauksessa/sopimuksessa sovittua määräaikaa palvelun tarjoamiselle pidennetään vastaavasti.</p>


<p>Osa 3 Sopiminen, sopimuksen laajuus</p>
<p>Kukin palvelu on tilattava erikseen sopimuskumppanin toimesta (yksittäinen tilaus/palvelutasosopimus). Sopimuspuolet toimittavat toisilleen vastavuoroisesti tilauksen täyttämiseen tarvittavat sisällöt. Jos seurantaohjelmat ovat integroituna näihin sisältöihin, niitä saa muuttaa tai poistaa toinen sopimuskumppani vain etukäteen neuvoteltuaan ja sovittuaan siitä.</p>
<p>Matthias Kupperschmidt laatii sopimuskumppanille laskun jokaisesta toteutetusta kampanjasta, jossa näkyvät selvästi kaikki kohdassa 1 määritellyt tiedot.</p>

<p>Osa 4 Sopimuskumppanin velvoitteet</p>
<p>Asiakas sitoutuu tukemaan Matthias Kupperschmidtin toteuttamia toimenpiteitä. Asiakas toimittaa Matthias Kupperschmidtille kaikki tilauksen täyttämiseen tarvittavat tiedot ja tiedot.</p>
<p>Sopimuskumppani sitoutuu varmistamaan, että hänen tarjoamansa ja Matthias Kupperschmidtin palveluiden käytön yhteydessä oleva sisältö ei riko Tanskan voimassa olevaa lainsäädäntöä; tämä koskee erityisesti laitonta, moraalitonta tai pornografista sisältöä tai sellaista sisältöä, joka ylistää sotaa tai voi olla moraalinen riski lapsille ja nuorille tai vaikuttaa negatiivisesti heidän hyvinvointiinsa.</p>
<p>Sopimuskumppanin on myös ilmoitettava kaikista tapahtumista ja olosuhteista, joilla saattaa olla merkitystä sopimuksen asianmukaisen ja ajantasaisen toteutuksen kannalta. Tämä koskee myös tapahtumia ja olosuhteita, joista sopimuskumppani tulee tietoiseksi sopimuksen voimassaoloaikana. Projektin viivästykset, jotka johtuvat siitä, että sopimuskumppani ei täytä tarvittavia yhteistyövelvoitteita tai ei täytä niitä ajoissa, ovat sopimuskumppanin vastuulla eivätkä vaikuta Matthias Kupperschmidtin korvausvaatimukseen.</p>
<p>Kohdassa 1 määritellyn velvoitteen rikkominen antaa Matthias Kupperschmidtille oikeuden irtisanoa sopimus Matthias Kupperschmidtin ja sopimuskumppanin välillä välittömästi. Matthias Kupperschmidt pidättää oikeuden välittömään irtisanomiseen, vaikka on vain epäilys siitä, että sopimuskumppani levittää tai sietää kohdassa 1 kuvattua sisältöä. Matthias Kupperschmidt on oikeutettu mutta ei velvollinen valvomaan sisällön laillisuutta.</p>
<p>Sopimuskumppani sitoutuu noudattamaan tarkasti Matthias Kupperschmidtin vaatimuksia teknisistä ja sisällöllisistä spesifikaatioista. Kaikki viivästykset ja/tai muutokset Matthias Kupperschmidtin tarjoamissa palveluissa, jotka johtuvat näiden vaatimusten noudattamatta jättämisestä, ovat sopimuskumppanin vastuulla eivätkä luo vaatimuksia Matthias Kupperschmidtiä vastaan.</p>
<p>Lisäksi sopimuskumppani on vastuussa siitä, että kaikki hänen sopimusvelvoitteidensa täyttämiseen ja yhteistyön asianmukaiseen toteuttamiseen tarvittavat tiedot ja asiakirjat toimitetaan Matthias Kupperschmidtille ajoissa, täydellisinä ja täyttämiseen sopivassa muodossa ennen sopimuksen sovittua toteuttamista.</p>
<p>Jos yksi osapuoli estyy tarjoamasta sopimuksen mukaista palvelua ja/tai sisältöä olosuhteiden vuoksi, joista se ei ole vastuussa, tämän osapuolen on ilmoitettava siitä toiselle osapuolelle ja lähetettävä viipymättä olosuhteisiin sopeutettuja sisältöjä ja/tai palveluita. Jos edellä mainitut olosuhteet kestävät yli 24 tuntia, osapuolet sopivat keskenään, miten edetä.</p>
<p>Sopimuskumppanilla ei ole oikeutta käyttää, kopioida, levittää, julkaista, muuttaa tai hyödyntää millään muulla tavalla Matthias Kupperschmidtin, sen asiakkaiden ja kumppaneiden tavaramerkkejä ja/tai logoja, ellei Matthias Kupperschmidt ole nimenomaisesti antanut siihen kirjallista suostumusta etukäteen. Lisäksi sopimuskumppani on velvollinen kunnioittamaan kolmansien osapuolten tekijänoikeuksia tai tavaramerkkioikeuksia, sekä saksalaisia että ulkomaisia.</p>
<p>Sopimuskumppani suostuu hänen sisältöjensä, tietojensa ja muiden tietojensa siirtämiseen kolmansille osapuolille siinä määrin kuin se on tarpeen ja vaaditaan osapuolten välisen sopimussuhteen täyttämiseksi.</p>

<p>Osa 5 Sopimuksen kesto/irtisanominen</p>
<p>Matthias Kupperschmidtin kustannusarviot eivät ole sitovia. Matthias Kupperschmidt sitoutuu ilmoittamaan sopimuskumppanille välittömästi mahdollisista eroista todellisissa kustannuksissa verrattuna arvioituun määrään, jos ero on yli kymmenen prosenttia.</p>
<p>Matthias Kupperschmidtin ja sopimuskumppanin välinen sopimus päättyy sovitun määräajan umpeutuessa. Muilta osin sopimuksen kesto voidaan nähdä tarjouksesta.</p>
<p>Jos sopimuskumppanin kanssa on sovittu vähimmäismääräaika, sopimus jatkuu automaattisesti sovitun määräajan, ellei jatkamista vastusteta viimeistään 30 päivää ennen kyseisen sopimuskauden päättymistä.</p>
<p>Määräajaksi tehdyt sopimukset voidaan irtisanoa molemmin puolin kirjallisesti milloin tahansa kolmen kuukauden irtisanomisajalla kuukauden loppuun.</p>
<p>Oikeus irtisanoa sopimus hyvän syyn vuoksi säilyy. Hyvä syy on erityisesti annettu, jos yksi osapuolista rikkoo olennaisia sopimusvelvoitteita tai -velvollisuuksia, jotka johtuvat sopimuksesta tai Tanskan voimassa olevasta laista, eikä palauta sopimuksen mukaista tilaa toisen osapuolen kirjallisesta varoituksesta huolimatta 7 päivän kuluessa varoituksen vastaanottamisesta. Varoituksessa on määriteltävä velvoitteen tai velvollisuuden rikkominen ja ilmoitettava mahdollisuuksista irtisanoa sopimus hyvän syyn vuoksi. Olennaiset sopimusvelvoitteet ovat erityisesti salassapitovelvoitteet.</p>

<p>Matthias Kupperschmidtille hyvä syy on annettu, jos</p>
<p>on haettu sopimuskumppanin varojen konkurssi- tai maksukyvyttömyysmenettelyn aloittamista</p>
<ol>
<li>muut olennaisten sopimusvelvoitteiden tai -velvollisuuksien rikkomiset, jos niitä ei ole välittömästi poistettu varoituksesta huolimatta.</li>
</ol>
<p>Sopimuksen irtisanomiseen saakka kumpikin sopimuskumppani säilyttää oikeutensa mahdollisiin maksuihin ja palkkioihin, joita vielä kertyy. Tähän ajankohtaan saakka sopimuskumppanit ovat myös velvollisia täyttämään sopimusvelvoitteensa. Kaikki velvoitteet, jotka ylittävät sopimuskauden, erityisesti tietosuojaa ja salassapitoa koskevat velvoitteet, säilyvät muuttumattomina.</p>
<p>Kaikki irtisanomiset on tehtävä kirjallisesti tai sähköpostilla.</p>

<p>Osa 6 Palkkiot ja eräpäivät</p>
<p>Sovittu palkkio erääntyy konsultoinnin ja/tai toimenpiteiden toteutuksen päättyessä. Hyväksyntää ei tarvita.</p>
<p>Matthias Kupperschmidtille kuuluu oikeus kohtuulliseen maksuun jo ennen konsultoinnin ja toimenpiteiden toteutuksen päättymistä. Epäselvissä tapauksissa kohtuullisuus perustuu jo tehtyyn työaikaan. Pysyvässä konsultoinnissa Matthias Kupperschmidt on oikeutettu laskuttamaan palvelut välittömästi niiden suorittamisen jälkeen. Palkkio perustuu Matthias Kupperschmidtin tarjoukseen.</p>

<p>Osa 7 Rahansiirto, maksuehdot</p>
<p>Palkkiot tai myynnin jakaminen tapahtuu aina yksittäisessä tilauksessa määritellyn erittelyn mukaisesti.</p>
<p>Ellei yksittäisessä tilauksessa ole toisin sovittu, Matthias Kupperschmidt suorittaa selvityksen kuukausittain. Kaikki myyntiluvut ja palkkiot ovat nettoarvoja; ALV esitetään erikseen.</p>
<p>Matthias Kupperschmidtille kuuluu oikeus vaatia ennakkomaksuja jopa 100 % tilauksen arvosta; ennakkomaksut erääntyvät sopimuksen allekirjoittamisen yhteydessä.</p>
<p>Myöhästyneen maksun tapauksessa Matthias Kupperschmidtille kuuluu oikeus periä viivästyskorkoa sopimuskumppanilta – samalla pidättäen oikeuden vaatia lisävahingonkorvausta – viisi prosenttiyksikköä yli kyseisen peruskoron. Viivästyskorko on pienempi, jos sopimuskumppani osoittaa pienemmän vahingon.</p>
<p>Sopimuskumppanilla on pidätysoikeus tai kuittausoikeus vain, jos sopimuskumppanin vaatimukset ovat riidattomia ja oikeudellisesti vahvistettuja.</p>

<p>Osa 8 Omistusoikeudet ja tekijänoikeussuoja, käyttöoikeudet</p>
<p>Matthias Kupperschmidtin tarjoamien palveluiden tekijänoikeudet ja muut omistusoikeudet säilyvät Matthias Kupperschmidtillä.</p>
<p>Matthias Kupperschmidt myöntää sopimuskumppanille ei-yksinomaiset, väliaikaiset ja alueellisesti rajoittamattomat käyttöoikeudet toimitettujen palveluiden hyödyntämiseen sopimuksen mukaisesti (työn tulokset).</p>
<p>Sopimuskumppanilla ei kuitenkaan ole oikeutta myydä toimitettua sisältöä ja tietoa kolmansille osapuolille tai tehdä niitä muuten kolmansien osapuolten saataville niiden tarkoitetun käyttötarkoituksen ulkopuolella.</p>
<p>Matthias Kupperschmidtin toimittamiin palveluihin tehtävät muutokset, jotka suorittaa sopimuskumppani tai kolmas osapuoli, jonka sopimuskumppani on siihen valtuuttanut, ovat sallittuja vain Matthias Kupperschmidtin nimenomaisella suostumuksella.</p>
<p>Käyttöoikeuksien käyttäminen on sallittua vain, jos sopimuskumppani ei ole maksamatta erääntynyttä maksua kyseisellä hetkellä.</p>
<p>Työntekijöiden, edustajien ja muiden kolmansien osapuolien kanssa tehtyjen sopimusten perusteella Matthias Kupperschmidt varmistaa, että sopimuskumppani voi vapaasti käyttää sopimuksen mukaisia käyttöoikeuksia.</p>

<p>Osa 9 Takuu ja vastuu</p>
<p>Matthias Kupperschmidt käyttää palveluitaan teknisten mahdollisuuksiensa puitteissa eikä anna takuuta tai takeita palveluidensa keskeytymättömästä ja/tai jatkuvasti virheettömästä saatavuudesta; erityisesti ei linjan katkeamisista ja/tai yhteyshäiriöistä, laitteisto- ja/tai ohjelmistovirheistä eikä kolmansien osapuolien toimista (esim. viruksista tai "denial of service" -hyökkäyksistä). Sopimuspuolet sopivat, että tällaisten keskeytysten ja/tai virheiden esiintyminen ei anna oikeutta vaatia korvausta tai hyvitystä.</p>
<p>Virheet ovat palvelukuvauksesta poikkeamisia, jotka rajoittavat sopimuksen mukaista käyttöä. Kaikki virheet on ilmoitettava Matthias Kupperschmidtille yksityiskohtaisesti. Osapuolet tekevät yhteistyötä virheanalyysin ja -korjauksen suhteen. Korjaus suoritetaan virhevastuun puitteissa eikä aiheuta kustannuksia sopimuskumppanille.</p>
<p>Sopimuskumppani antaa Matthias Kupperschmidtille kohtuullisen ajan korjaukseen. Jos korjaus ei onnistu toisen kohtuullisen lisäajan (vähintään kuitenkin 15 työpäivän) aikana, sopimuskumppanilla on oikeus vaatia maksun alennusta tai korvausta vahingosta, jos hän on ilmoittanut tällaisesta toimenpiteestä asetettaessa lisäaikaa.</p>
<p>Sopimuskumppanin virhevaatimuksia ei voida enää esittää, jos hän on tehnyt muutoksia palveluihin itse tai on antanut kolmansien osapuolien tehdä muutoksia ilman ennakkokirjallista suostumusta, ellei sopimuskumppani pysty osoittamaan, että virheet eivät johdu näistä muutoksista tai että nämä muutokset eivät estä virheanalyysiä ja -korjausta tai tekevät sen vain vähäisessä määrin.</p>
<p>Virhevaatimukset on periaatteessa suljettu pois, jos sopimuskumppani käyttää palveluita käyttöehdoissa määritellyistä poikkeavin ehdoin. Sopimuskumppanin vastuulla on osoittaa, että virhe ei johtunut tällaisesta poikkeamasta.</p>
<p>Vaatimukset vahingoista Tanskan tavarantoimituslain mukaan sopimuksen alkaessa olevien puutteiden osalta, joista Matthias Kupperschmidt ei ole vastuussa, sekä vain huolimattomien puutteiden osalta ovat poissuljettuja.</p>
<p>Virhevaatimukset vanhentuvat yhden vuoden kuluttua hyväksynnästä; palveluiden osalta, jotka eivät ole hyväksyntäkelpoisia tai eivät tarvitse hyväksyntää, yhden vuoden kuluttua toimituksesta/vastaanotosta tai valmistumisen ja/tai palvelun tarjoamisen ilmoittamisesta.</p>
<p>Matthias Kupperschmidt ei ole vastuussa teknisistä virheistä, joiden syyt eivät kuulu Matthias Kupperschmidtin vastuulle, eikä vahingoista, jotka johtuvat ylivoimaisesta esteestä. Poikkeustapauksissa voi käydä niin, että yksittäisiä tietoja, palveluita tai muita teknisiä tai tosiasiallisia toimintoja ei voida ottaa huomioon tai laskuttaa oikein Matthias Kupperschmidtin toimesta. Sopimuskumppanin vaatimus tähän perustuen – erityisesti vahingonkorvausvaatimukset tai arvonkorvausvaatimukset – Matthias Kupperschmidtiä vastaan on poissuljettu.</p>
<p>Matthias Kupperschmidt on vastuussa vain vahingoista, jotka johtuvat olennaisten sopimusvelvoitteiden rikkomisesta tai taattujen ominaisuuksien ja ominaisuuksien puuttumisesta. Lisäksi Matthias Kupperschmidt on vastuussa vain, jos vahinko on aiheutunut törkeästä huolimattomuudesta tai tahallisesta aikomuksesta.</p>
<p>Matthias Kupperschmidt on vastuussa lievästi huolimattomasta olennaisen sopimusvelvoitteen rikkomisesta ja sopimuksen ulkopuolisesta vastuusta vain hengenmenetyksen, fyysisen vamman ja terveydelle aiheutuneen vahingon, takuiden rikkomisen, olennaisten sopimusvelvoitteiden sekä tuotevastuulain mukaisesti vain tyypillisen vahingon määrään, joka oli ennakoitavissa sopimuksen allekirjoitushetkellä. Vahinko lasketaan viimeisten kuuden kuukauden keskimääräisen kuukausitilausmäärän perusteella. Erityisesti lievän huolimattomuuden aiheuttamien olennaisten sopimusvelvoitteiden rikkomisen osalta vahingonkorvausvastuu, välillinen vahinko ja seurannaisvahinko ovat periaatteessa poissuljettuja.</p>
<p>Välillisten vahinkojen, epäsuorien vahinkojen ja/tai voitonmenetysten vastuu on poissuljettu, ellei kyseessä ole tahallinen toiminta. Määrällisesti vastuu on rajoitettu odotettavissa olevaan normaaliin vahinkoon Tanskan sisällä; enintään kuitenkin kyseisen tilauksen mukaisiin kokonaispalkkioihin. Tämä vastuunrajoitus koskee samoin Matthias Kupperschmidtin työntekijöiden tai edustajien tahallisesti tai törkeästä huolimattomuudesta aiheuttamia vahinkoja.</p>
<p>Jos sopimuskumppani on tehnyt muutoksia sopimuksen mukaiseen palveluun tai sen ympäristöön tai antanut kolmansien osapuolien tehdä muutoksia, kaikki sopimuskumppanin vaatimukset edellä mainituista ehdoista vanhentuvat, ellei virhe ole aiheutunut, kokonaan tai osittain, sopimuskumppanin tekemistä muutoksista eikä korjaus ole tullut vaikeammaksi muutosten vuoksi.</p>
<p>Nämä vastuunrajoitukset koskevat mutatis mutandis kaikkia Matthias Kupperschmidtin työntekijöitä ja edustajia. Matthias Kupperschmidtin vastuu tuotevastuulain perusteella pysyy muuttumattomana.</p>
<p>Kaikki vahingonkorvausvaatimukset tai muut vaatimukset, joita voidaan esittää Matthias Kupperschmidtiä vastaan palvelun ominaisuuksien ja kunnon tai virheiden vuoksi, vanhentuvat 6 (kuuden) kuukauden kuluttua kyseisen sopimuksen päättymisestä.</p>
<p>Sopimuskumppani on vastuussa Matthias Kupperschmidtille hänen toimittamiensa ja Matthias Kupperschmidtin käyttöön antamiensa sisältöjen, asiakirjojen, linkkien ja muiden materiaalien (jäljempänä "asiakirjat") osalta yhteistyöprojektin toteuttamiseksi.</p>
<p>Tarvittaessa sopimuskumppani tarkistaa omalla kustannuksellaan, täyttävätkö Matthias Kupperschmidtin tarjoamat palvelut asiaankuuluvat lakisääteiset vaatimukset. Matthias Kupperschmidt käynnistää tällaisen ulkoisen oikeudellisen tarkistuksen vain sopimuskumppanin nimenomaisesta kirjallisesta pyynnöstä; siihen liittyvät kustannukset maksaa sopimuskumppani.</p>
<p>Jos kolmannet osapuolet esittävät Matthias Kupperschmidtiä vastaan vaatimuksia tekijänoikeuksien loukkaamisen ja/tai asiaankuuluvien lakisääteisten säännösten rikkomisen vuoksi, joka on tapahtunut sopimuskumppanin velvollisuuksien piiriin kuuluvalla alueella, sopimuskumppani tekee kaikkensa puolustaakseen Matthias Kupperschmidtiä esitettyjä vaatimuksia tai oikeuksia vastaan ja poistaakseen väitetyn loukkauksen. Sopimuskumppani on myös vastuussa Matthias Kupperschmidtille kaikista kolmansien osapuolien aiheuttamista vahingoista, jotka ovat aiheutuneet siitä, että tilaus on suoritettu osittain, virheellisesti, viivästyneesti, myöhässä tai ei ollenkaan. Sama koskee sopimuskumppanin edustajia. Sopimuskumppani on velvollinen näyttämään toteen tilauksen asianmukaisen suorittamisen.</p>
<p>Sopimuskumppani vapauttaa Matthias Kupperschmidtin kaikista vahingonkorvausvaatimuksista, vastuuväitteistä ja kustannuksista, joita Matthias Kupperschmidtille aiheutuu siitä, että häntä vastaan esitetään vaatimus, jonka mukaan sopimuskumppanin asiakirjat loukkaavat kilpailulakia, rikkovat kolmansien osapuolten teollisoikeuksia tai muita lakeja ja säännöksiä. Erityisesti sopimuskumppani vapauttaa Matthias Kupperschmidtin ensimmäisestä pyynnöstä välittömästi kaikista kolmansien osapuolten vaatimuksista, joita esitetään Matthias Kupperschmidtiä vastaan käytetyn sisällön tai hyperlinkillä liitetyn tarjouksen vuoksi, erityisesti Tanskan tietosuojalain (DDPA) ja EU:n yleisen tietosuoja-asetuksen (GDPR) mukaisesti, ja palauttaa pyynnöstä kaikki kustannukset, joita Matthias Kupperschmidtille aiheutuu asianmukaisten oikeustoimien vuoksi.</p>

<p>Osa 10 Verkkosivustojen sallittavuus</p>
<p>morefire ei tarkista, rikkovatko asiakkaan verkkosivustojen sisällöt kolmansien osapuolten oikeuksia tai ovatko ne perustettu yksittäisten hakukoneoperaattoreiden ohjeiden mukaisesti. Asiakas on yksin vastuussa kolmansien osapuolten mahdollisista seuraamuksista.</p>
<p>Asiakas on myös vastuussa verkkosivustojensa sisällön laillisesta hyväksyttävyydestä sekä hänen toimittamiensa tietojen, mukaan lukien hakutermit tai avainsanat; sama koskee kolmansien osapuolten oikeuksien suojaamista, erityisesti tekijänoikeuksien, kilpailulakien ja rikoslakien osalta.</p>

<p>Osa 11 Palvelusopimus</p>
<p>Palvelusopimusten puitteissa työskenteleviä työntekijöitä ei saa houkutella asiakkaan palvelukseen 24 kuukauden kuluessa sopimuksen myöntämisestä. Jos tällainen houkuttelu tapahtuu tänä aikana, urakoitsijalla on oikeus kertaluonteiseen maksuun, jonka määrä on 75 000 €.</p>

<p>Osa 12 Tietosuoja</p>
<p>Ilman erillistä suostumuslausumaa henkilötietoja – ellei toisin ole määritelty – kerätään, käsitellään ja käytetään vain palvelujen tarjoamista, palvelujen käyttöä ja tarvittaessa laskutusta varten.</p>
<p>Sopimuskumppanin on noudatettava kaikkia sovellettavia tietosuojasäännöksiä, erityisesti Tanskan tietosuojalain (DDPA) ja EU:n yleisen tietosuoja-asetuksen (GDPR) säännöksiä.</p>
<p>Sopimuskumppani takaa, että hän käyttää mahdollisesti keräämiään tietoja yksinomaan ilmoitettuun tarkoitukseen sekä markkinatutkimus- ja mainontatarkoituksiin yrityksen sisällä, sikäli kuin se on tarpeen tehtyjen liiketoimien toteuttamiseksi ja syntyneen käyttäjäsuhteen ylläpitämiseksi ja jos se on lain mukaan sallittua ja käyttäjän toivomaa. Sopimuskumppanin oikeus myydä tietoja kolmansille osapuolille on poissuljettu, ja syytteet nostetaan.</p>

<p>Osa 13 Luottamuksellisuus ja salassapito</p>
<p>Sopimuskumppani sitoutuu käyttämään Matthias Kupperschmidtiltä saatuja asiakirjoja, tietoa ja kokemuksia yksinomaan tämän sopimuksen tarkoituksiin. Lisäksi sopimuspuolet sopivat kolmansien osapuolten suhteen tehtyjen sopimusten sisällön sekä niiden toteutuksen aikana saadun tiedon salassapidosta. Sopimuskumppani on velvollinen neuvottelemaan Matthias Kupperschmidtin kanssa, jos herää epäilys siitä, onko tietty tieto käsiteltävä luottamuksellisesti.</p>
<p>Mahdollisen alihankinnan tapauksessa sopimuskumppani sitouttaa kyseisen alihankkijan tämän määräyksen mukaisiin velvoitteisiin.</p>
<p>Sopimuskumppani varmistaa tämän salassapitovelvoitteen noudattamisen hänen työntekijöidensä ja kaikkien sopimuksen toteuttamiseen osallistuvien kolmansien osapuolien osalta, joilla on pääsy ensimmäisessä kappaleessa määriteltyihin asiakirjoihin ja esineisiin.</p>

<p>Osa 14 Muutokset</p>
<p>Matthias Kupperschmidt tarjoaa palvelunsa vastaavien tuotekuvausten mukaisesti. Matthias Kupperschmidt pidättää oikeuden muokata, laajentaa tai mukauttaa palveluita kokonaisuudessaan tai osittain tai muokata, laajentaa tai mukauttaa yksittäisten tuotteiden ja palveluiden tyyppiä ja sisältöä palveluiden tarjoamisen yhteydessä milloin tahansa ja toistuvasti tarjouksen parantamiseksi, erityisesti sen toimintoja, suunnittelua ja teknistä kehitystä. Sopimuskumppanille ilmoitetaan merkittävistä muutoksista tuotteisiin sähköpostitse ajoissa. Uusi versio tuotekuvauksista liitetään mukaan. Jos sopimuskumppani ei hyväksy muutosta, hänellä on oikeus irtisanoa sopimus Matthias Kupperschmidtin kanssa välittömästi. Jos sopimuskumppani ei vastusta muutosta kahden viikon kuluessa, ilmoitetut muutokset katsotaan uusiksi ehdoiksi.</p>
<p>Lisäksi Matthias Kupperschmidtillä on oikeus lopettaa palveluiden osana tarjottuja yksittäisiä toimintoja – erityisesti muutettujen lakisääteisten vaatimusten, pitkäkestoisten tappioiden, teknisten vaikeuksien tai asiakkaiden, sopimuskumppaneiden tai kolmansien osapuolten väärinkäytösten vuoksi.</p>
<p>Matthias Kupperschmidt pidättää myös nimenomaisesti oikeuden tehdä hinnanmuutoksia, joista Matthias Kupperschmidt ilmoittaa sopimuskumppanille kohtuullisen ajan kuluessa.</p>
<p>Matthias Kupperschmidt pidättää myös oikeuden muuttaa tai täydentää näitä yleisiä ehtoja, erityisesti jos uusia palveluita otetaan käyttöön tai jos muutokset ovat tarpeen lakisääteisten säännösten vuoksi. Matthias Kupperschmidt ilmoittaa sopimuskumppanille muutoksista tai lisäyksistä yleisiin ehtoihin. Jos sopimuskumppani ei hyväksy muutoksia, hän voi vastustaa niitä kahden viikon kuluessa siitä, kun ne on tehty hänelle kirjallisesti tiedoksi.</p>
<p>Jos sopimuskumppani vastustaa palveluiden, näiden yleisten ehtojen tai yksittäisten tuotteiden erityisehtojen muutoksia, Matthias Kupperschmidtille kuuluu oikeus irtisanoa ilman irtisanomisaikaa näiden yleisten ehtojen mukaisesti tehdyt sopimukset. Jos sopimuskumppani ei vastusta, hänen suostumuksensa katsotaan annetuksi edellä mainittujen määräaikojen päättyessä. Matthias Kupperschmidt huomauttaa määräajan pituudesta ja sen päättymisen merkityksestä ilmoittaessaan yleisten ehtojen muutoksesta.</p>

<p>Osa 15 Loppusäännökset</p>
<p>Ilmoitusten lähettäminen Matthias Kupperschmidtille sähköpostitse täyttää kirjallisen muodon vaatimuksen vain, jos se on nimenomaisesti mainittu näissä käyttöehdoissa ja jos Matthias Kupperschmidt on nimenomaisesti hyväksynyt sen etukäteen.</p>
<p>Sopimuskumppanin sopimusoikeuksien ja -velvoitteiden siirtäminen kolmannelle osapuolelle on sallittu vain Matthias Kupperschmidtin etukäteen antamalla kirjallisella suostumuksella.</p>
<p>Sopimuskumppanilla on pidätysoikeus vain kiistattomien tai oikeudellisesti vahvistettujen vaatimusten osalta.</p>
<p>Sivusopimuksia ei ole. Kaikki osapuolten väliset sopimukset on tehtävä kirjallisesti. Tämä koskee myös kirjallisen muodon vaatimuksen kumoamista.</p>
<p>Sopimuskumppanin on ilmoitettava Matthias Kupperschmidtille ajoissa vireillä olevista maksukyvyttömyysmenettelyistä, jotta varmistetaan sopimuspalvelujen ja velvoitteiden täyttäminen tai mahdollistetaan sopimuksen yhteisymmärryksessä tapahtuva päättäminen.</p>
<p>Jos yleisten ehtojen yksittäiset määräykset ovat kokonaisuudessaan tai osittain tehottomia tai jos ne sisältävät aukon, muiden määräysten tai tällaisen määräyksen osien tehokkuus ei vaikuta siihen. Tässä tapauksessa osapuolet ovat velvollisia osallistumaan sellaisten uusien määräysten luomiseen, joiden avulla saadaan aikaan laillisesti vaikuttava kaupallinen tulos, joka on lähimpänä tehotonta tai epätäydellistä määräystä, samalla kun säilytetään molempien osapuolten edut.</p>
<p>Sopimusosapuolten suorituspaikka on Matthias Kupperschmidtin liiketoimipaikka.</p>
<p>Tanskan osavaltion lakia sovelletaan yksinomaan, lukuun ottamatta Yhdistyneiden Kansakuntien yleissopimusta kansainvälisten tavaroiden kauppasopimuksista</p>
<p>(CISG) ja viittaukset ulkomaan lakiin.</p>
<p>Jos asiakas on kauppias, julkisoikeudellinen oikeushenkilö tai julkisoikeudellinen erityisrahasto tai jos hänellä ei ole rekisteröityä toimipaikkaa Tanskassa, yksinomainen oikeuspaikka on Kööpenhamina, Tanska. Matthias Kupperschmidt on myös oikeutettu nostamaan kanteen sopimuskumppanin toimipaikassa.</p>

<p>Osa 16 Riitojen ratkaisu</p>
<p>Euroopan komissio on perustanut alustan verkkoriitojen ratkaisemista varten. Se on saatavilla osoitteessa: http://ec.europa.eu/consumers/odr/.</p>
<p>Kuluttajat voivat käyttää alustaa riitojen ratkaisemiseen. Matthias Kupperschmidt ei ole halukas eikä velvollinen osallistumaan riitojen ratkaisumenettelyyn kuluttajariitalautakunnassa, ellei osallistumiseen ole lakisääteistä velvoitetta.</p>
<p>Kööpenhamina, 17. kesäkuuta 2024</p>

</MainContent>
</React.Fragment>

</Layout>
);
};
export default LegalNotice;



  